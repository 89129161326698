const myPlugin = {
    install(app) {

        // Price formatting
        app.config.globalProperties.$priceFormat = (price, company) => {

            if (company === 'Pacific Destinations') {
                return Number(price / 100).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'NZD'
                })
            } else if (company === 'Goway Inbound') {
                return Number(price / 100).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'AUD'
                })
            } else {
                return Number(price / 100).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'NZD'
                })
            }
        }

        // Strip pre tags
        app.config.globalProperties.$stripPreTags = (key) => {

            return key.replace(/<pre data-placeholder="Translation" dir="ltr">(.*?)<\/pre>/gs, '$1')

        }

        // Convert to friendly date
        app.config.globalProperties.$readableDate = (key) => {
            const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            var monthNum = key.substring(5, 7)
            var month = months[monthNum - 1]
            var day = key.substring(8, 10)

            return day + ' ' + month
        }

        // Convert to friendly date including year
        app.config.globalProperties.$readableDateAndYear = (key) => {
            const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            var monthNum = key.substring(5, 7)
            var year = key.substring(0, 4)
            var month = months[monthNum - 1]
            var day = key.substring(8, 10)

            return day + ' ' + month + ' ' + year
        }

        // Convert room code to room name
        app.config.globalProperties.$roomName = (key) => {
            if (key === 'DB') {
                return 'Double Room'
            } else if (key === 'SG') {
                return 'Single Room'
            } else if (key === 'TW') {
                return 'Twin Room'
            } else if (key === 'TR') {
                return 'Triple Room'
            } else if (key === 'QD') {
                return 'Quad Room'
            } else if (key === 'OT') {
                return 'Other Room'
            } else {
                return ''
            }
        }

        // Convert room code to room name
        app.config.globalProperties.$readableTime = (time) => {
            return time.slice(0, 2) + ':' + time.slice(2)
        }

        // Trim string length
        app.config.globalProperties.$trimString = (string, length) => {
            if (string.length > length) {
                return string.slice(0, length) + '...'
            } else {
                return string
            }
        }

        // Remove -cl from supplier confirmations
        app.config.globalProperties.$cleanSupplierConfirmation = (confirmation) => {
            confirmation = confirmation.replaceAll(' -cl', '');
            confirmation = confirmation.replaceAll(' -ar', '');
            confirmation = confirmation.replaceAll(' -rc', '');
            return confirmation
        }

        //  Pluralise based on a quantity
        app.config.globalProperties.$pluralise = (quantity) => {
            if (quantity > 1) {
                return 's'
            }
        }

        // Copy text to clipboard
        app.config.globalProperties.$copyToClipboard = (text) => {
            navigator.clipboard.writeText(text)
        }

        //  Return tailwind classes for different tourplan statuses
        app.config.globalProperties.$getColorClass = (val) => {

            if (typeof val !== 'string') {
                console.warn('Expected a string, but received:', val)
                return ''
            }

            const greenStatuses = ['Confirmed', 'Allotment', 'Available', 'Conf']
            const amberStatuses = ['Quote', 'Processing', 'On Request', 'Requested', 'Mixed', 'pending', 'Wait', 'Amendment']
            const redStatuses = ['Cancelled', 'Unable', 'Cancellation', 'Unavailable', 'Full']

            if (greenStatuses.some(function(status) { return val.indexOf(status) !== -1; })) {
                return 'text-green-500 dark:text-green-400';
            } else if (amberStatuses.some(function(status) { return val.indexOf(status) !== -1; })) {
                return 'text-amber-500 dark:text-amber-400';
            } else if (redStatuses.some(function(status) { return val.indexOf(status) !== -1; })) {
                return 'text-red-500 dark:text-red-400';
            }
            return ''

        }

        //  Get a date in the past calculated using provided length in days. Used for limiting dateFrom in search forms.
        app.config.globalProperties.$getPastDate = (length) => {
            // Create a new Date object with today's date
            const today = new Date();

            // Subtract the length from today's date
            today.setDate(today.getDate() - length);

            // Get the year, month, and day
            const year = today.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
            const day = String(today.getDate()).padStart(2, '0');

            // Return the date as YYYY-MM-DD
            return `${year}-${month}-${day}`;
        }

    }
}

export default myPlugin;
